<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect" default-expand-all row-key="id" grouped="true" border :span-method="objectSpanMethod">
		<el-table-column type="selection" width="55" v-if="false">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="Ad Account" prop="accountId" min-width="120">
		</el-table-column>
		<el-table-column label="汇率" prop="rate" min-width="100">
		</el-table-column>
		<el-table-column label="Pixel ID" prop="pixel" min-width="100">
		</el-table-column>
		<el-table-column label="国家" prop="countryCode" min-width="100">
		</el-table-column>
		<el-table-column label="Page ID" prop="pageId" min-width="100">
		</el-table-column>
		<el-table-column label="Page Name" prop="pageName" min-width="100">
		</el-table-column>
		<el-table-column label="Ad Limit" prop="adLimit" min-width="100">
		</el-table-column>
		<el-table-column label="Ins ID" prop="insId" min-width="100">
		</el-table-column>
		<el-table-column label="Ins Name" prop="insName" min-width="100">
		</el-table-column>
		<el-table-column label="发布状态" min-width="150">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		methods: {
			objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			    if (columnIndex <= 3 || columnIndex === 11) {
					const pages = row.pages || []
					const rowspan = Array.isArray(row.pages) ? Math.max(pages.length, 1) : 0
			        return {
			        	rowspan,
			        	colspan: 1
			        }
				}
			}
		}
	}
</script>

<style lang="scss">

</style>
