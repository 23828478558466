<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="form.channel=queryForm.channel;visible.add=true">添加配置</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item-countries v-model="queryForm.countryCode" :site="queryForm.channel"></form-item-countries>
				<form-item label="账户:" style="width:200px;">
					<el-input v-model="queryForm.accountId" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="pixel:" style="width:200px;">
					<el-input v-model="queryForm.pixel" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="发布状态:" style="width:230px;">
					<el-select v-model="queryForm.active" size="mini" clearable>
						<el-option label="开启" :value="true"></el-option>
						<el-option label="暂停" :value="false"></el-option>
					</el-select>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<page-pixel-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></page-pixel-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" width="1000px">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="80px" size="mini">
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item-sites :abbr="false" v-model="form.channel" prop="channel" labelWidth="80px" size="mini"></el-form-item-sites>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Ad Account:" prop="accountId" label-width="100px">
							<el-input v-model="form.accountId" placeholder="请输入Account ID"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="汇率:" prop="rate">
							<el-input :value="form.rate" placeholder="请输入" @input="valueChanged"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Pixel ID:" prop="pixel" label-width="100px">
							<el-input v-model="form.pixel" placeholder="请输入Pixel ID"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
				<div class="d-flex flex-row align-items-center mb-2">
					<h5 class="mb-0 mr-2">Page 使用规则</h5>
					<el-button style="border: 1px dashed;" size="mini" @click="form.pages.push({active: true, countryCode: '', pageId: '', insId:'', type: 'PAGE'})">+添加规则</el-button>
				</div>
				<el-row :gutter="20" v-for="(page, index) in form.pages" :key="index">
					<el-col :span="7">
						<el-form-item-countries
							v-model="page.countryCode"
							label="国家:" 
							:prop="`pages.${index}.countryCode`" 
							label-width="50px" 
							:site="form.channel" 
							size="mini" 
							:clearable="false" 
							:disabled="index===0" 
							:rules="{required:true,message:'国家不能为空'}"
							@update="page.countryCode=$event"
							>
						</el-form-item-countries>
					</el-col>
					<el-col :span="7">
						<el-form-item 
							label="Page ID:" 
							label-width="80px" 
							:prop="`pages.${index}.pageId`" 
							:rules="{required:true,message:'Page ID不能为空'}">
							<el-input v-model="page.pageId" placeholder="请输入Page ID"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Ins ID:" label-width="60px" :prop="`pages.${index}.insId`">
							<el-input v-model="page.insId" placeholder="请输入Ins ID"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="4">
						<div class="d-flex flex-row align-items-center justify-content-between" style="max-width:100px;height:30px;">
							<el-switch v-if="form.id" v-model="page.active"></el-switch>
							<el-button v-if="index > 0" type="text" class="text-primary" @click="form.pages.splice(index, 1)">删除</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate" size="mini">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import PagePixelTable from '@/components/contents/page-pixel-table.vue'
	import config from './minixs/index.js'
	import Lodash from 'lodash'
	const InitialForm = {
		channel: '',
		accountId: '',
		rate: '',
		pixel: '',
		pages: [{
			countryCode: 'ALL',
			pageId: '',
			active: true,
			insId: '',
			type: 'PAGE'
		}]
	}
	export default {
		mixins:[config],
		components: {
			PagePixelTable
		},
		data() {
			return {
				queryForm: {
					channel: '',
					countryCode: '',
					accountId: '',
					pixel: '',
					active: ''
				},
				tableData: [],
				q: {
					channel: ''
				},
				visible: {
					add: false
				},
				form: Lodash.cloneDeep(InitialForm),
				rules: {
					channel: [{
						required: true,
						message: '站点不能为空'
					}],
					accountId: [{
						required: true,
						message: 'Ad Account不能为空'
					}],
					rate: [{
						required: true,
						message: '汇率不能为空'
					}],
					pixel: [{
						required: true,
						message: 'Pixel ID不能为空'
					}]
				},
				fetchOnCreated:false,
				namespace: 'fb-contents-pixel'
			}
		},
		methods: {
			getId(item) {
				return item.groupId
			},
			fillForm(data) {
				this.form = {
					...Lodash.cloneDeep(InitialForm),
					...data,
					pages: data.pages.map(v => {
						return {
							...v,
							type: 'PAGE'
						}
					})
				}
			},
			valueChanged(val) {
				const num = Number(val)
				if (!isNaN(num)) {
					this.form.rate = val 
				}
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.$nextTick(() => {
						this.form = Lodash.cloneDeep(InitialForm)
					})
				}
			}
		}
	}
</script>

<style>
</style>
